/* eslint-disable jsx-a11y/img-redundant-alt */
import "./about.css";
import React from "react";
import Information from "./Information";
import MyImg from "../../assets/Grad_Picture.jpg";

const About = () => {
  return (
    <section className="about__section" id="about">
      <h2 className="section__title">About Me</h2>
      <span className="section__subtitle">Introduction</span>

      <div className="about__container container">
        <img src={MyImg} alt="The picture of Karl" className="about__img" />
        <div className="about__data">
          <Information />
        </div>
      </div>

      <div className="about__introduction container">
        <p className="about__description">
          Mabuhay! Welcome to my portfolio website! My name is Karl, a
          full-stack developer with a slight bias towards back-end, great to
          meet you—virtually, that is.
        </p>

        <p className="about__description">
          My coding journey began in elementary school with HTML. In 3rd grade,
          my teacher showed us how to create basic static websites using Notepad
          and saving them with a '.html' extension. I had a blast decorating my
          first webpage with colors, quirky fonts, and arranging everything just
          the way I wanted. My interest in computers heightened after this
          point. I started joining <em>Computer Clubs</em> learning more basic
          things that piqued my interest more such as powerpoint, excel, making
          animation, and coding. Slowly, I was gaining interest in video games.
          I invested numerous hours in playing games and slowly getting curious
          how to make one.
        </p>

        <p className="about__description">
          As the years went by, I chose to pursue a career in software
          development with a dream of coding my own game someday. Along the way,
          I've picked up several programming languages through small projects to
          grasp the fundamentals. While I initially learned most languages in
          class, I've spent countless hours refining my skills, gradually
          mastering each one. However, Java stands out as my top language—I have
          invested the most time in mastering it and making a few programs with
          it.
        </p>

        <p className="about__description">
          Even though my journey has just begun, I am excited about the
          possibilities ahead in the world of software and game development.
          Feel free to explore my portfolio and reach out—I look forward to
          connecting with fellow enthusiasts and potential collaborators.
        </p>
      </div>
    </section>
  );
};

export default About;
