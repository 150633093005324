import React from "react";
import "./skills.css";
const Frontend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Frontend</h3>

      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">HTML</h3>
              <div className="skills__level intermediateadvanced"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">CSS</h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">React</h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">Git</h3>
              <div className="skills__level basicintermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">JavaScript</h3>
              <div className="skills__level basic"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">NextJS</h3>
              <div className="skills__level learning"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">Tailwind CSS</h3>
              <div className="skills__level learning"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">TypeScript</h3>
              <div className="skills__level semilearning"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Frontend;