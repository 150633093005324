import React from "react";
import "./skills.css";
const Backend = () => {
  return (
    <div className="skills__content">
      <h3 className="skills__title">Backend</h3>

      <div className="skills__box">
        <div className="skills__group">
          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">Java</h3>
              <div className="skills__level advancedexpert"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">MySQL</h3>
              <div className="skills__level intermediateadvanced"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">MongoDB</h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">C/C++</h3>
              <div className="skills__level intermediate"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">PostgreSQL</h3>
              <div className="skills__level basic"></div>
            </div>
          </div>

          <div className="skills__data">
            <i className="bx bx-badge-check"></i>
            <div className="flex-grow">
              <h3 className="skills__name">Python</h3>
              <div className="skills__level basiclearning"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Backend;
