import React from "react";

const Information = () => {
  return (
    <div className="about__info">
      <div className="about__box">
        <i className="bx bx-award about__icon"></i>
        <h3 className="about__title">Experience</h3>
        <span className="about__subtitle">5 years in College</span>
      </div>

      <div className="about__box">
        <i className="uil uil-notes about__icon"></i>
        <h3 className="about__title">Completed</h3>
        <span className="about__subtitle">20+ In-class Projects</span>
      </div>

      <div className="about__achievement">
        <i className="uil uil-award about__icon"></i>
        <h3 className="about__title">Educational Achievements</h3>
        <span className="about__subtitle">
          <b>Magna Cum Laude</b>{" "}
        </span>
        <br />
        <span className="about__subtitle">Dean's Lister (2019-2024)</span>
      </div>
    </div>
  );
};

export default Information;
