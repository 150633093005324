/* eslint-disable jsx-a11y/aria-props */
import React from "react";

const Data = () => {
  return (
    <div className="home__data">
      <h1 className="home__title">
        Karl Xavier
        <i className="em em-flag-ph"></i>
        <i className="em em-flag-um"></i>
      </h1>
      <h3 className="home__subtitle">Software Engineer</h3>
      <p className="home__description">
        I am a newly graduate Software Engineer from San Francisco State
        University.
      </p>
    </div>
  );
};

export default Data;
